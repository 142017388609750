import Google from '@assets/Google.svg';
import Error from '@components/error/error.component';
import {
  getUserById,
  permissionMap,
  signIn,
  validatePermissions,
} from '@lib/services/user.service';
import { getAuth } from 'firebase/auth';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

const SocialFirebaseAuth = () => {
  const [error, setError] = useState();
  const router = useRouter();

  const signInUser = async () => {
    const auth = getAuth();

    try {
      const user = await signIn(auth);
      const firestoreUser = await getUserById(user.user.uid);

      const validPermissions = validatePermissions(firestoreUser.permissions);
      if (!validPermissions.length) {
        return {
          redirect: {
            permanent: false,
            destination: '/not-authorized',
          },
        };
      }

      if (validPermissions.length === 1) {
        // redirect
        const config = permissionMap[validPermissions[0]];
        return router.push(config.route);
      }

      router.push('/callback');
    } catch (error) {
      setError(error?.message ?? null);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      {error && <Error>{error}</Error>}

      <button
        className="tracking-[0.01em] font-semibold w-full px-8 gap-3 bg-button-tertiary hover:bg-button-tertiary-hover text-emphasis-high flex h-12 items-center justify-center rounded-3xl"
        onClick={() => signInUser()}
        data-testid="google-btn"
      >
        <Image
          unoptimized
          priority={true}
          src={Google}
          width={24}
          height={24}
          alt="Google logo"
        />

        <span>Authenticate with Google</span>
      </button>
    </div>
  );
};

export default SocialFirebaseAuth;
