import BasketLogo from '@assets/basketLogoSideBar.svg';
import SocialFirebaseAuth from '@components/social-firebase-auth/social-firebase-auth.component';
import initAuth from 'initAuth';
import { AuthAction, withUser, withUserTokenSSR } from 'next-firebase-auth';
import { NextSeo } from 'next-seo';
import Image from 'next/image';
import React from 'react';

initAuth();

const AuthPage = () => (
  <main className="w-full font-be-vietnam flex items-center flex-col justify-center h-screen gap-6">
    <div className="flex flex-col gap-4">
      <Image
        unoptimized
        src={BasketLogo}
        height={48}
        width={48}
        alt="Basket Logo"
      />
      <h1 className="text-2xl font-semibold tracking-[-0.025em]">
        Basket Live
      </h1>
    </div>

    <NextSeo
      title="Basket Live - Sign in"
      description="Sign in to view the Basket Live feed"
    />

    <div className="mx-auto w-11/12 md:w-[384px]">
      <div className="w-full text-center flex flex-col gap-3">
        <p className="text-emphasis-medium text-xs tracking-[0.025em]">
          Sign in with your Basket Google account
        </p>

        <SocialFirebaseAuth />
      </div>
    </div>
  </main>
);

export const getServerSideProps = withUserTokenSSR({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
})();

export default withUser()(AuthPage);
