import {
  Auth,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
//import { getFirebaseAdmin } from 'next-firebase-auth';

export const signIn = async (auth: Auth) => {
  const defaultProvider = new GoogleAuthProvider();
  const googleResult = await signInWithPopup(auth, defaultProvider);
  GoogleAuthProvider.credentialFromResult(googleResult);
  const googleAdditionalDetails = getAdditionalUserInfo(googleResult);

  return {
    user: googleResult.user,
    additionalDetails: googleAdditionalDetails,
  };
};

export const getUserById = async function (userId: string) {
  const firestore = getFirestore();
  const userDocRef = doc(firestore, 'users', userId);
  const userDoc = await getDoc(userDocRef);

  return userDoc.data();
};

export enum Permission {
  LiveFeedView = 'live:stream',
  ProductReviewer = 'live:productreviewer',
  SelectorEngineer = 'live:selectorengineer',
  ItemReviewer = 'live:itemreviewer',
  Admin = 'live:admin',
}

export const hasAccess = (permission: Permission, permissions?: string[]) => {
  if (!permissions) return false;

  return permissions.includes(permission);
};

export const permissionMap = {
  [Permission.ProductReviewer]: {
    title: 'Products reviewer',
    route: '/review/products-reviewer',
  },
  [Permission.LiveFeedView]: {
    title: 'Live Stream',
    route: '/stream',
  },
  [Permission.SelectorEngineer]: {
    title: 'Selector submission',
    route: '/review/new-config',
  },
  [Permission.ItemReviewer]: {
    title: 'Item Review',
    route: '/review/item-review',
  },
  [Permission.Admin]: {
    title: 'Admin Dashboard',
    route: '/admin',
  },
};

export const validatePermissions = (permissions: Permission[]) =>
  permissions.filter(item => permissionMap.hasOwnProperty(item));
