import classNames from 'classnames';
import React from 'react';

interface props {
  className?: string;
  children?: React.ReactNode;
}

const Error = ({ className, children }: props) => (
  <div
    className={classNames(
      'w-full py-3 px-6 bg-daff-light text-daff-darker rounded-lg h-auto mt-6 text-sm text-center',
      className,
    )}
  >
    {children}
  </div>
);

export default Error;
